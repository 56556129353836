import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Calculation } from '../../common/calculator/calculator';
import { CalculationSide, Function, Operation } from '../../common/calculator/calculator-operations.enum';
import { CalculatorAnswerDto } from '../../model/answer/calculator/calculator-answer-dto';


@Component({
	selector: 'app-calculator',
	templateUrl: './calculator.component.html',
	styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent implements OnInit {

	@Input() public answer!: CalculatorAnswerDto;

	public function: typeof Function = Function;
	public visorText: string = '0';
	public calculations: Calculation[] = [];
	public currentCalculation: Calculation = new Calculation(0);
	public clearOnNextNumber: boolean = false;
	public calculationEvaluated: boolean = false;
	public newNumberAfterEvaluation: boolean = false;
	public previousCalculationWasAutoCalculated: boolean = false; // This is a variable for calculations which auto complete e.g x^2, x^3 and square root.
	public operationPressedAfterCalculation: boolean = false;
	public maxDigits: number = 14;

    public ngOnInit(): void {

		if (this.answer && this.answer.answerJson) {
			const prevCalculations = JSON.parse(this.answer.answerJson) as Calculation[];

			// Methods not transferred when deserialising
			this.calculations = prevCalculations.map(c => Object.assign(new Calculation(c.result!), c));
		}
    }

	public input(func: Function): void {
		if (!this.isOperation(func)) {
			this.operationPressedAfterCalculation = false;
		}
		switch (func) {
			case Function.Zero:
			case Function.One:
			case Function.Two:
			case Function.Three:
			case Function.Four:
			case Function.Five:
			case Function.Six:
			case Function.Seven:
			case Function.Eight:
			case Function.Nine:
				this.inputToVisor(String(func));
				break;
			case Function.Decimal:
				this.tryAddDecimal();
				break;
			case Function.Add:
			case Function.Multiply:
			case Function.Subtract:
			case Function.Divide:
			case Function.PowerOf:
				this.applyOperation(func);
				break;
			case Function.Equals:
				this.calculate();
				break;
			case Function.C:
			case Function.CE:
				this.clearCurrentCalculationBasedOnContext(func);
				break;
			case Function.Negate:
				this.negateInput();
				break;
			case Function.Percentage:
				this.convertToPercentage();
				break;
			case Function.Square:
			case Function.Cube:
				const powerOfVal = func === Function.Square ? 2 : 3;
				this.applyPowerOf(powerOfVal);
				this.previousCalculationWasAutoCalculated = true;
				break;
			case Function.Pi:
				if (this.currentCalculation.left && this.currentCalculation.operation) {
					if (this.currentCalculation.right) {
						this.currentCalculation.C();
					}
					this.inputToVisor(String(3.14159265359), false);
				} else {
					this.currentCalculation.CE();
					this.inputToVisor(String(3.14159265359), true);
				}
				break;
			case Function.SquareRoot:
				this.calculateSquareRoot();
				this.previousCalculationWasAutoCalculated = true;
				break;
			default:
				return;
		}
	}

	public tryAddDecimal(): void {
		if (!this.currentCalculation.pendingDecimal &&
			((this.currentCalculation.getCurrentCalculationSide() === CalculationSide.Left && !String(this.currentCalculation.left).includes('.')) ||
			 this.currentCalculation.getCurrentCalculationSide() === CalculationSide.Right && !String(this.currentCalculation.right).includes('.'))) {
			this.inputToVisor('.');
			this.currentCalculation.pendingDecimal = true;
		}
	}

	public inputToVisor(input: string, reset: boolean = false): void {
		if (this.visorText.length <= this.maxDigits || this.clearOnNextNumber || this.calculationEvaluated) {
			if (this.calculationEvaluated) {
				this.currentCalculation.left = null;
				this.currentCalculation.operation = null;
				this.currentCalculation.right = null;
				this.calculationEvaluated = false;
				this.newNumberAfterEvaluation = true;
			} else {
				this.newNumberAfterEvaluation = false;
			}
			if (this.clearOnNextNumber || reset || this.visorText === '0') {
				this.visorText = '';
				this.clearOnNextNumber = false;
			}
			this.visorText = `${this.visorText}${input}`;
			this.currentCalculation.addInputToCalculation(input);
		}
	}

	public applyOperation(func: Function): void {
		if (this.currentCalculation.left !== null) {
			let operationSymbol: string;
			this.currentCalculation.trailingZeros = 0; // Ensure trailing zeros are reset if the left side is complete (e.g 5.00 then * would cause the trailing zeros to carry to the right side).
			this.currentCalculation.pendingDecimal = false; // Same as above.
			this.currentCalculation.valueBeforeTrail = null; // Same as above.

			if (this.calculationEvaluated) {
				// If the last input was equals, flag that the following input was an operation change

				this.operationPressedAfterCalculation = true;
				this.currentCalculation.operation = <unknown>func as Operation;
			}

			if (this.currentCalculation.left && this.currentCalculation.right) {
				this.calculate();
			}
			this.currentCalculation.operation = <unknown>func as Operation;


			this.calculationEvaluated = false;
			this.clearOnNextNumber = true;
		}
	}

	public calculate(): number | void {
		if ((!this.newNumberAfterEvaluation || this.currentCalculation.oneSidedOperation) && (this.currentCalculation.right !== null || this.currentCalculation.oneSidedOperation)) {
			if (!this.currentCalculation.isComplete && this.currentCalculation.notSquareRoot) {
				const previousCalculation: Calculation = [this.calculations[this.calculations.length - 1]][0];

				if (!previousCalculation || this.currentCalculation.isEmpty || this.previousCalculationWasAutoCalculated) {
					return;
				}

				if (this.operationPressedAfterCalculation && !this.calculationEvaluated) {
					this.currentCalculation.right = this.currentCalculation.left;
				} else {
					this.currentCalculation.operation = previousCalculation.operation;
					this.currentCalculation.right = previousCalculation.right;
				}
			}

			const currentCalculationResult: number = this.currentCalculation.calculateResult();
			this.calculations.push(this.currentCalculation);
			this.currentCalculation = new Calculation(currentCalculationResult);
			this.visorText = String(Number(currentCalculationResult.toFixed(9)));
			this.clearOnNextNumber = true;
			this.calculationEvaluated = true;
			this.currentCalculation.oneSidedOperation = undefined;
			if (this.previousCalculationWasAutoCalculated) {
				this.currentCalculation.operation = null;
				this.previousCalculationWasAutoCalculated = false;
			}
			this.setJSONAnswer();
			return currentCalculationResult;
		}
	}

	public clearCurrentCalculationBasedOnContext(func: Function): void {
		switch (func) {
			case Function.CE:
				this.currentCalculation.CE();
				break;
			case Function.C:
				this.currentCalculation.C();
				break;
		}

		this.currentCalculation.pendingDecimal = false;
		this.visorText = '0';
	}

	public negateInput(): void {
		if (this.visorText !== '0' && !this.currentCalculation.pendingDecimal) {
			const negatedValue = this.currentCalculation.negateCurrentValue();
			this.visorText = String(negatedValue);
		}
	}

	public convertToPercentage(): void {
		if (this.visorText !== '0' && !this.currentCalculation.pendingDecimal) {
			const convertedPercentageValue = this.currentCalculation.convertToPercentage();
			this.visorText = String(convertedPercentageValue);
		}
	}

	public applyPowerOf(powerOfVal: number): void {
		if (this.currentCalculation.left !== 0) {
			this.applyOperation(Function.PowerOf);
			this.currentCalculation.right = powerOfVal;
			this.currentCalculation.oneSidedOperation = true;
			this.calculate();
		}
	}

	public calculateSquareRoot(): void {
		this.applyOperation(Function.SquareRoot);
		this.currentCalculation.oneSidedOperation = true;
		this.calculate();
	}

	private setJSONAnswer(): void {

		if (this.calculations.length && this.answer) {
			this.answer.answerJson = JSON.stringify(this.calculations);
		}
	}

	public getCurrentPartialCalculation(): string {
		return this.currentCalculation.renderPartialCalculation();
	}

	private isOperation(func: Function): boolean {
		if (func === Function.Equals) { return this.operationPressedAfterCalculation; }

		return (func === Function.PowerOf ||
				func === Function.Multiply ||
				func === Function.Add ||
				func === Function.Divide ||
				func === Function.Subtract) ? true : false;
	}
}
