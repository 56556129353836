import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormAnswerDTO } from '../../model/answer/form/form-answer-dto';
import { FormAnswerItemDTO } from '../../model/answer/form/form-answer-item-dto';
import { FormBuilderElementTypeIdentifier } from '../../model/answer/form/form-builder-element-type-identifier';
import { FormBuilderElementInputTypeIdentifier } from '../../model/answer/form/form-builder-element-input-type-identifier';

@Component({
	selector: 'app-form-item',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

	@Input() public answer!: FormAnswerDTO;


	public FormBuilderElementTypeIdentifier = FormBuilderElementTypeIdentifier;
	public FormBuilderElementInputTypeIdentifier = FormBuilderElementInputTypeIdentifier;


	ngOnInit(): void {
		this.answer.formItems = this.answer.formItems.sort((a: FormAnswerItemDTO, b: FormAnswerItemDTO) => a.index - b.index);
	}
}
