<div class="container-fluid">
  <div class="row">
    <div class="col d-flex justify-content-center">
      <div cdkDropList class="reorder-list" (cdkDropListDropped)="drop($event)">
        @for (row of this.answer.reorderAnswerRows; track row) {
          <div class="reorder-row" cdkDrag>{{row.text}}</div>
        }
      </div>
    </div>
  </div>
</div>


