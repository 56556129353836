<div class='document-container'>
	<div *ngIf="!isPreview">
		<p>Upload your documents below:</p>
		<div class="row">
			<div class="col-12 cdn-wrapper">
				<highfield-uploads-shared-uploaded-files [linkId]="answer.id" [categoryId]="coreExamCDNCategoryId" [useProxy]="shouldUseProxy" [proxyUserId]="coreExamsUserId" [configuration]="fileUploadConfig" (nodeCountChanged)="markAsAnswered()"></highfield-uploads-shared-uploaded-files>
			</div>
		</div>
	</div>
	<div *ngIf="isPreview">
		<div class="row">
			<p>Previewing of a document upload answer is not supported at the current time</p>
		</div>
	</div>
</div>
